import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ce285f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "placeholder", "disabled", "min", "max"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("fieldset", {
      class: _normalizeClass(["Input", {'Input--Disabled':_ctx.disabled}])
    }, [
      _createElementVNode("legend", null, _toDisplayString(_ctx.label), 1 /* TEXT */),
      _withDirectives(_createElementVNode("input", {
        type: _ctx.type,
        placeholder: _ctx.placeholder,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        disabled: _ctx.disabled,
        min: _ctx.min,
        max: _ctx.max,
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur'))),
        onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).value)))
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_1), [
        [_vModelDynamic, _ctx.value]
      ])
    ], 2 /* CLASS */)
  ]))
}